
.homeBox{
    background-image:url(../images/background.png);
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
      
}
 

.Ty1{
    color:#fff;
    position:relative;
    left: 65%;
    top: 24%;
}
.Ty2{
    color:#fff;
    position:relative;
    left: 70%;
    top: 23%;
}
.Ty3 ,.Ty4{
    color:#fff;
    position: relative;
    left: 68%;
    top: 28%;
}
